import { initializeApp } from "firebase/app"
import { initializeAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCNXbNytLA6LgvV6iQP0gn79jkINsVWSWw",
    authDomain: "propayaprod.firebaseapp.com",
    projectId: "propayaprod",
    storageBucket: "propayaprod.appspot.com",
    messagingSenderId: "543255005631",
    appId: "1:543255005631:web:fc7c80b03920714fb8790a",
    measurementId: "G-J4FR4LGBR9"
  };
  
  const firebaseApp = initializeApp(firebaseConfig);
  const firebaseAuth = initializeAuth(firebaseApp);
  export const FIREBASE_APP = firebaseApp;
  export const FIREBASE_AUTH = firebaseAuth;