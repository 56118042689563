import { EmailAuthProvider, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { StyledFirebaseAuth } from "react-firebaseui";
import { Box, Typography } from "@mui/material";
import { FIREBASE_AUTH } from "../lib/firebase";

// Configure FirebaseUI.
const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/signedIn',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        GoogleAuthProvider.PROVIDER_ID,
        EmailAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
        signInSuccessWithAuthResult: () => false
    }
};

export function SignIn() {
    return (
        <Box height={"100vh"} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
            <Typography variant="h2" textAlign={"center"}>
                Sign In:
            </Typography>
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={FIREBASE_AUTH} />
        </Box>
    );
}