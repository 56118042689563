import { Box, Button, ButtonBase, Grid2, Tooltip, Typography, useTheme } from "@mui/material";
import { AnalysisResult } from "../lib/interfaces";
import { Download, InsertDriveFileOutlined } from "@mui/icons-material";
import colors from "../lib/colors";

export const data: AnalysisResult = {
    "concessions": {
        "freeRent": "Yes",
        "freeRentExplanation": "Months 1-8 to be paid at Lease signing (MMR+OES) = $37,448.88",
        "freeRentSource": "Page 1",
        "reducedRent": "No",
        "reducedRentExplanation": "N/A",
        "reducedRentSource": "N/A",
        "tenantImprovement": "Yes",
        "tenantImprovementExplanation": "Landlord and Tenant agree that Tenant improvements will cost approximately $43,500.00. Landlord will be responsible for the costs, as owner/builder.",
        "tenantImprovementSource": "Page 45, Exhibit D"
    },
    "landlord": "Crystal Springs Musich, L.P.",
    "leaseDate": "2019-12-03",
    "leaseExpirationDate": "2022-01-31",
    "leaseType": {
        "leaseType": "Net",
        "sources": [
            "Page 2",
            "Page 9",
            "Page 12",
            "Page 13",
            "Page 17"
        ]
    },
    "leasedPremises": [
        "1,041 S.F. Second Floor"
    ],
    "leasedSF": "1,041 S.F.",
    "monthlyBaseRent": {
        "amountOriginal": "3,903.75",
        "currentAmount": "3,903.75",
        "sources": [
            "Page 1"
        ]
    },
    "monthlyEffectiveRent": "3,903.75",
    "monthsOnOriginalLease": {
        "numberOfMonths": 24,
        "source": "Page 1"
    },
    "monthsOnRenewedLeases": {
        "numberOfMonths": 0,
        "sources": []
    },
    "numberOfMonthsRemainingOnMostRecentRenewedLease": 0,
    "numberOfMonthsRemainingOnOriginalLease": 0,
    "rentalCommencementDate": "2020-02-01",
    "rentalPriceChanges": {
        "changes": "Annual Cost of Living Rent Adjustment, (Index: All Items, All Urban Consumers, U.S. City Average. Beginning on the First day of the Second Lease Year.) The First Adjustment Date is February 1, 2021.",
        "sources": [
            "Page 1"
        ]
    },
    "tenant": "MyDocPlus LLC",
    "commonAreaMaintenanceResponsibility": {
        "expenseStop": "None",
        "landlordPercentage": "N/A",
        "responsibility": "Tenant",
        "source": "Page 2, Lines 1-13",
        "tenantPercentage": "1.19%"
    },
    "earlyTerminationRights": {
        "explanation": "Landlord may terminate if damage or destruction is to more than 50% of the square footage of the Premises, repairs cannot be reasonably completed within 180 days, and tenant ceases to occupy the premises.\nIf more than 60% of the square footage of the Premises is damaged or destroyed and repairs cannot reasonably be completed within 180 days under then existing law, Tenant may, at its option, terminate this Lease by written notice to Landlord given within thirty (30) days after the occurrence of such damage or destruction.",
        "hasOtherTerminationRights": "Yes",
        "source": "Page 26, lines 2-7"
    },
    "insuranceCondemnationProceeds": {
        "landlordControlOverCondemnationProceeds": "Yes",
        "landlordControlOverInsuranceProceeds": "Yes",
        "landlordRequiredToRebuild": "Yes",
        "landlordRequiredToRebuildOnCondemnation": "Yes",
        "sources": [
            "Page 27 Section 9"
        ],
        "tenantTerminationRightsOnCondemnation": "Yes",
        "tenantTerminationRightsOnInsurance": "Yes"
    },
    "insuranceResponsibility": {
        "expenseStop": "None",
        "landlordPercentage": "N/A",
        "responsibility": "Tenant",
        "source": "Page 2, Lines 1-13; Pages 23-24 Section 7",
        "tenantPercentage": "1.27%"
    },
    "leaseSignedByAllParties": "Yes",
    "operatingResponsibility": {
        "expenseStop": "None",
        "landlordPercentage": "N/A",
        "responsibility": "Tenant",
        "source": "Page 2",
        "tenantPercentage": "100%"
    },
    "propertyTaxesResponsibility": {
        "expenseStop": "None",
        "landlordPercentage": "N/A",
        "responsibility": "Tenant",
        "source": "Page 2, Lines 1-13, Page 12, Section 2.6",
        "tenantPercentage": "11.84%"
    },
    "purchaseOptions": {
        "explanation": "The lease does not mention any purchase options for the tenant.",
        "hasPurchaseOptions": "No",
        "sources": []
    },
    "renewalOptions": {
        "details": "One (1) five (5) year option to renew at Fair Market Rent subject to Annual Cost of Living Rent Adjustments.",
        "hasOptions": "Yes",
        "source": "Page 2, Lines 27-34"
    },
    "subordination": {
        "isSubordinateWithoutSNDA": "Yes",
        "source": "Page 26, Article 13",
        "sourceText": "This Lease, at Landlord's option, shall be subordinate to any ground lease, mortgage, deed of trust, or any other hypothecation for security hereafter placed upon the Premises and to any and all advances made upon the security thereof and to all renewals, modifications, consolidations, replacements and extensions thereof."
    },
    "terminationConditions": "Tenant may terminate if more than 60% of the Leased Premises is damaged or destroyed and Landlord elects not to restore the premises, and repairs cannot reasonably be completed within 180 days under then existing law. Tenant may also terminate if 33 1/3% or more of the replacement cost of the Leased Premises is destroyed during the last year of the term, or any extended term.",
    "utilitiesResponsibility": {
        "expenseStop": "None",
        "landlordPercentage": "N/A",
        "responsibility": "Tenant",
        "source": "Page 2, lines 17-25; Page 16, Section 6.1",
        "tenantPercentage": "100%"
    },
    "reportSASURL": "https://leaseanalysisb2b.blob.core.windows.net/test-org/230%20MyDocPlus.xlsx?st=2024-10-12T09%3A30%3A24Z&se=2024-10-12T10%3A30%3A24Z&sp=r&sv=2024-11-04&sr=b&sig=JHApf7Eb1asPAMhh/jMblfGDBtk8D4kvF552YCPihWQ%3D"
}

interface AnalysisDataDisplayProps {
    data: AnalysisResult
}

export function AnalysisDataDisplay({ data }: AnalysisDataDisplayProps) {
    const theme = useTheme();

    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} overflow={"auto"}>
            <Button
                color="secondary"
                variant='contained'
                startIcon={<Download />}
                sx={{ height: "48px", marginBottom: theme.spacing(2), marginTop: theme.spacing(4), width: "90%", fontSize: 18 }}
                href={data.reportSASURL}
                target="_blank"
            >
                Download Report
            </Button>
            <Box margin={theme.spacing(2)} borderRadius={theme.spacing(4)} bgcolor={"white"} padding={theme.spacing(4)} width={"85%"} >
                <Typography textAlign={"center"} variant="h4" marginBottom={theme.spacing(4)}>Lease Analysis</Typography>
                <Grid2 container>
                    <Grid2 size={6}>
                        <Typography variant="h6">Landlord</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.landlord}</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography variant="h6">Tenant</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.tenant}</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography variant="h6">Leased Square Footage</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.leasedSF}</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography variant="h6">Lease Date</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.leaseDate}</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography variant="h6">Rental Commencement Date</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.rentalCommencementDate}</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography variant="h6">Lease Expiration Date</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.leaseExpirationDate}</Typography>
                    </Grid2>
                    <Grid2 size={12}>
                        <Typography variant="h6">Original Term</Typography>
                    </Grid2>
                    <Grid2 size={1} />
                    <Grid2 size={5}>
                        <Typography variant="h6">Total Months</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Tooltip placement="left-end" title={data.monthsOnOriginalLease.source}>
                            <Typography>{data.monthsOnOriginalLease.numberOfMonths}</Typography>
                        </Tooltip>
                    </Grid2>
                    <Grid2 size={1} />
                    <Grid2 size={5}>
                        <Typography variant="h6">Months Remaining</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.numberOfMonthsRemainingOnOriginalLease}</Typography>
                    </Grid2>
                    <Grid2 size={12}>
                        <Typography variant="h6">Renewal(s) Term</Typography>
                    </Grid2>
                    <Grid2 size={1} />
                    <Grid2 size={5}>
                        <Typography variant="h6">Total Months</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Tooltip placement="left-end" title={data.monthsOnRenewedLeases.sources.join(", ")}>
                            <Typography>{data.monthsOnRenewedLeases.numberOfMonths}</Typography>
                        </Tooltip>
                    </Grid2>
                    <Grid2 size={1} />
                    <Grid2 size={5}>
                        <Typography variant="h6">Months Remaining</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.numberOfMonthsRemainingOnMostRecentRenewedLease}</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography variant="h6">Base Rent</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Tooltip placement="left-end" title={data.monthlyBaseRent.sources.join(", ")}>
                            <Typography>{data.monthlyBaseRent.currentAmount}</Typography>
                        </Tooltip>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography variant="h6">Lease Type</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Tooltip placement="left-end" title={data.leaseType.sources.join(", ")}>
                            <Typography>{data.leaseType.leaseType}</Typography>
                        </Tooltip>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography variant="h6">Rental Price Changes</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Tooltip placement="left-end" title={data.rentalPriceChanges.sources.join(", ")}>
                            <Typography>{data.rentalPriceChanges.changes}</Typography>
                        </Tooltip>
                    </Grid2>
                    <Grid2 size={12}>
                        <Typography variant="h6">Concessions</Typography>
                    </Grid2>
                    <Grid2 size={1} />
                    <Grid2 size={5}>
                        <Typography variant="h6">Free Rent?</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Tooltip placement="left-end" title={data.concessions.freeRentSource}>
                            <Typography>{data.concessions.freeRent}</Typography>
                        </Tooltip>
                    </Grid2>
                    <Grid2 size={1} />
                    <Grid2 size={5}>
                        <Typography variant="h6">Explanation</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.concessions.freeRentExplanation}</Typography>
                    </Grid2>
                    <Grid2 size={1} />
                    <Grid2 size={5}>
                        <Typography variant="h6">Tenant Improvement?</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Tooltip placement="left-end" title={data.concessions.tenantImprovementSource}>
                            <Typography>{data.concessions.tenantImprovement}</Typography>
                        </Tooltip>
                    </Grid2>
                    <Grid2 size={1} />
                    <Grid2 size={5}>
                        <Typography variant="h6">Explanation</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.concessions.tenantImprovementExplanation}</Typography>
                    </Grid2>
                    <Grid2 size={1} />
                    <Grid2 size={5}>
                        <Typography variant="h6">Reduced Rent</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Tooltip placement="left-end" title={data.concessions.reducedRentSource}>
                            <Typography>{data.concessions.reducedRent}</Typography>
                        </Tooltip>
                    </Grid2>
                    <Grid2 size={1} />
                    <Grid2 size={5}>
                        <Typography variant="h6">Explanation</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.concessions.reducedRentExplanation}</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography variant="h6">Effective Rent</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.monthlyEffectiveRent}</Typography>
                    </Grid2>
                    <Grid2 size={12}>
                        <Typography variant="h6">Expense Responsibilities</Typography>
                    </Grid2>
                    <Grid2 size={1} />
                    <Grid2 size={11}>
                        <Tooltip placement="left-end" title={data.operatingResponsibility.source}>
                            <Typography variant="h6">Operating</Typography>
                        </Tooltip>
                    </Grid2>
                    <Grid2 size={2} />
                    <Grid2 size={4}>
                        <Typography variant="h6">Responsible Party</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.operatingResponsibility.responsibility}</Typography>
                    </Grid2>
                    <Grid2 size={2} />
                    <Grid2 size={4}>
                        <Typography variant="h6">Expense Stop/Cap</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.operatingResponsibility.expenseStop}</Typography>
                    </Grid2>
                    <Grid2 size={2} />
                    <Grid2 size={4}>
                        <Typography variant="h6">Landlord Percentage</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.operatingResponsibility.landlordPercentage}</Typography>
                    </Grid2>
                    <Grid2 size={2} />
                    <Grid2 size={4}>
                        <Typography variant="h6">Tenant Percentage</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.operatingResponsibility.tenantPercentage}</Typography>
                    </Grid2>
                    <Grid2 size={1} />
                    <Grid2 size={11}>
                        <Tooltip placement="left-end" title={data.utilitiesResponsibility.source}>
                            <Typography variant="h6">Utilities</Typography>
                        </Tooltip>
                    </Grid2>
                    <Grid2 size={2} />
                    <Grid2 size={4}>
                        <Typography variant="h6">Responsible Party</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.utilitiesResponsibility.responsibility}</Typography>
                    </Grid2>
                    <Grid2 size={2} />
                    <Grid2 size={4}>
                        <Typography variant="h6">Expense Stop/Cap</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.utilitiesResponsibility.expenseStop}</Typography>
                    </Grid2>
                    <Grid2 size={2} />
                    <Grid2 size={4}>
                        <Typography variant="h6">Landlord Percentage</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.utilitiesResponsibility.landlordPercentage}</Typography>
                    </Grid2>
                    <Grid2 size={2} />
                    <Grid2 size={4}>
                        <Typography variant="h6">Tenant Percentage</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.utilitiesResponsibility.tenantPercentage}</Typography>
                    </Grid2>
                    <Grid2 size={1} />
                    <Grid2 size={11}>
                        <Tooltip placement="left-end" title={data.propertyTaxesResponsibility.source}>
                            <Typography variant="h6">Property Taxes</Typography>
                        </Tooltip>
                    </Grid2>
                    <Grid2 size={2} />
                    <Grid2 size={4}>
                        <Typography variant="h6">Responsible Party</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.propertyTaxesResponsibility.responsibility}</Typography>
                    </Grid2>
                    <Grid2 size={2} />
                    <Grid2 size={4}>
                        <Typography variant="h6">Expense Stop/Cap</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.propertyTaxesResponsibility.expenseStop}</Typography>
                    </Grid2>
                    <Grid2 size={2} />
                    <Grid2 size={4}>
                        <Typography variant="h6">Landlord Percentage</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.propertyTaxesResponsibility.landlordPercentage}</Typography>
                    </Grid2>
                    <Grid2 size={2} />
                    <Grid2 size={4}>
                        <Typography variant="h6">Tenant Percentage</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.propertyTaxesResponsibility.tenantPercentage}</Typography>
                    </Grid2>
                    <Grid2 size={1} />
                    <Grid2 size={11}>
                        <Tooltip placement="left-end" title={data.commonAreaMaintenanceResponsibility.source}>
                            <Typography variant="h6">Common Area Maintenance</Typography>
                        </Tooltip>
                    </Grid2>
                    <Grid2 size={2} />
                    <Grid2 size={4}>
                        <Typography variant="h6">Responsible Party</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.commonAreaMaintenanceResponsibility.responsibility}</Typography>
                    </Grid2>
                    <Grid2 size={2} />
                    <Grid2 size={4}>
                        <Typography variant="h6">Expense Stop/Cap</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.commonAreaMaintenanceResponsibility.expenseStop}</Typography>
                    </Grid2>
                    <Grid2 size={2} />
                    <Grid2 size={4}>
                        <Typography variant="h6">Landlord Percentage</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.commonAreaMaintenanceResponsibility.landlordPercentage}</Typography>
                    </Grid2>
                    <Grid2 size={2} />
                    <Grid2 size={4}>
                        <Typography variant="h6">Tenant Percentage</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.commonAreaMaintenanceResponsibility.tenantPercentage}</Typography>
                    </Grid2>
                    <Grid2 size={1} />
                    <Grid2 size={11}>
                        <Tooltip placement="left-end" title={data.insuranceResponsibility.source}>
                            <Typography variant="h6">Insurance</Typography>
                        </Tooltip>
                    </Grid2>
                    <Grid2 size={2} />
                    <Grid2 size={4}>
                        <Typography variant="h6">Responsible Party</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.insuranceResponsibility.responsibility}</Typography>
                    </Grid2>
                    <Grid2 size={2} />
                    <Grid2 size={4}>
                        <Typography variant="h6">Expense Stop/Cap</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.insuranceResponsibility.expenseStop}</Typography>
                    </Grid2>
                    <Grid2 size={2} />
                    <Grid2 size={4}>
                        <Typography variant="h6">Landlord Percentage</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.insuranceResponsibility.landlordPercentage}</Typography>
                    </Grid2>
                    <Grid2 size={2} />
                    <Grid2 size={4}>
                        <Typography variant="h6">Tenant Percentage</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.insuranceResponsibility.tenantPercentage}</Typography>
                    </Grid2>
                    <Grid2 size={12}>
                        <Tooltip placement="left-end" title={data.renewalOptions.source}>
                            <Typography variant="h6" >Renewals</Typography>
                        </Tooltip>
                    </Grid2>
                    <Grid2 size={1} />
                    <Grid2 size={5}>
                        <Typography variant="h6" >Has Renewal Options?</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.renewalOptions.hasOptions}</Typography>
                    </Grid2>
                    <Grid2 size={1} />
                    <Grid2 size={5}>
                        <Typography variant="h6" >Details</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.renewalOptions.details}</Typography>
                    </Grid2>
                    <Grid2 size={12}>
                        <Tooltip placement="left-end" title={data.subordination.source}>
                            <Typography variant="h6" >Subordination</Typography>
                        </Tooltip>
                    </Grid2>
                    <Grid2 size={1} />
                    <Grid2 size={5}>
                        <Typography variant="h6" >Subordinate without SNDA?</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.subordination.isSubordinateWithoutSNDA}</Typography>
                    </Grid2>
                    <Grid2 size={1} />
                    <Grid2 size={5}>
                        <Typography variant="h6" >Details</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.subordination.sourceText}</Typography>
                    </Grid2>
                    <Grid2 size={12}>
                        <Tooltip placement="left-end" title={data.insuranceCondemnationProceeds.sources.join(", ")}>
                            <Typography variant="h6" >Insurance and Condemnation Incidents</Typography>
                        </Tooltip>
                    </Grid2>
                    <Grid2 size={1} />
                    <Grid2 size={11}>
                        <Typography variant="h6" >Insurance</Typography>
                    </Grid2>
                    <Grid2 size={2} />
                    <Grid2 size={4}>
                        <Typography variant="h6" >Landlord Controls Proceeds?</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.insuranceCondemnationProceeds.landlordControlOverInsuranceProceeds}</Typography>
                    </Grid2>
                    <Grid2 size={2} />
                    <Grid2 size={4}>
                        <Typography variant="h6" >Lease Requires Landlord to Rebuild?</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.insuranceCondemnationProceeds.landlordRequiredToRebuild}</Typography>
                    </Grid2>
                    <Grid2 size={2} />
                    <Grid2 size={4}>
                        <Typography variant="h6" >Tenant Termination Rights?</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.insuranceCondemnationProceeds.tenantTerminationRightsOnInsurance}</Typography>
                    </Grid2>
                    <Grid2 size={1} />
                    <Grid2 size={11}>
                        <Typography variant="h6" >Condemnation</Typography>
                    </Grid2>
                    <Grid2 size={2} />
                    <Grid2 size={4}>
                        <Typography variant="h6" >Landlord Controls Proceeds?</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.insuranceCondemnationProceeds.landlordControlOverCondemnationProceeds}</Typography>
                    </Grid2>
                    <Grid2 size={2} />
                    <Grid2 size={4}>
                        <Typography variant="h6" >Lease Requires Landlord to Rebuild?</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.insuranceCondemnationProceeds.landlordRequiredToRebuildOnCondemnation}</Typography>
                    </Grid2>
                    <Grid2 size={2} />
                    <Grid2 size={4}>
                        <Typography variant="h6" >Tenant Termination Rights?</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.insuranceCondemnationProceeds.tenantTerminationRightsOnCondemnation}</Typography>
                    </Grid2>
                    <Grid2 size={12}>
                        <Tooltip placement="left-end" title={data.earlyTerminationRights.source}>
                            <Typography variant="h6" >Other Termination Rights</Typography>
                        </Tooltip>
                    </Grid2>
                    <Grid2 size={1} />
                    <Grid2 size={5}>
                        <Typography variant="h6" >Other Tenant Termination Rights?</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.earlyTerminationRights.hasOtherTerminationRights}</Typography>
                    </Grid2>
                    <Grid2 size={1} />
                    <Grid2 size={5}>
                        <Typography variant="h6" >Explanation</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.earlyTerminationRights.explanation}</Typography>
                    </Grid2>
                    <Grid2 size={12}>
                        <Tooltip placement="left-end" title={data.purchaseOptions.sources.join(", ")}>
                            <Typography variant="h6" >Purchase Options</Typography>
                        </Tooltip>
                    </Grid2>
                    <Grid2 size={1} />
                    <Grid2 size={5}>
                        <Typography variant="h6" >Tenant has purchase options?</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.purchaseOptions.hasPurchaseOptions}</Typography>
                    </Grid2>
                    <Grid2 size={1} />
                    <Grid2 size={5}>
                        <Typography variant="h6" >Explanation</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.purchaseOptions.explanation}</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography variant="h6" >Lease Signed by All Parties</Typography>
                    </Grid2>
                    <Grid2 size={6}>
                        <Typography>{data.leaseSignedByAllParties}</Typography>
                    </Grid2>
                </Grid2>
            </Box>
        </Box>
    );
}